.headerOfPage {
    color: #3479aa !important;
    text-align: center;
    padding: 0 2rem; /* creates the space */
}

.headerOfTable {
    background-color: #3479aa;
}

th{
    color: white;
    background: #0069cc !important;
  }

  .courseDetail {
    max-width: 100%;
    max-height: 100%;
  }

  .courseDetailDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  table, th, td {
    border: 1px solid white;
  }

  tbody tr:nth-child(odd){
    background-color: #cce6ff;
  }

.title {
  color: #0069cc;
}  

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.footerBackground {
   background-color: #80b3ff; 
   /* #ffaa80; */
}

.footerImage {
  justify-content: center;
  display: flex;
}
.footerTitle {
  justify-content: center;
  display: flex;
}

.courseDetailLink {
  font-size: larger;
}

.separator{
  display: flex;
  align-items: center;
}

.separator h2{
  padding: 0 2rem; /* creates the space */
}

.separator .line{
  flex: 1;
  height: 3px;
  background-color: #000;
}

.borderIntro {
  border: 3px solid #0069cc;
}

.imageSlide {
  object-fit: scale-down; max-width: 100%
}

#documentName {
  width: 70%;
}